<template>
    <div class="flex flex-col px-6">
        <div class="mt-8 grid grids-cols-1 xl:grid-cols-2 gap-5">
            <div class="col-span-2 xl:col-span-1">
                <div class="rounded p-4 l-box-shadow bg-white-pure">
                    <div class="flex pb-8 items-center">
                        <ReportSvg class="text-primary-light w-10 mr-2" />
                        <h1 class="text-black mr-2 text-xl lg:text-2xl" data-cy="name">Reports</h1>
                    </div>
                    <div class="flex items-center mb-6">
                        <div class="text-sm">
                            Select a location/program & date-range to download the report.
                        </div>
                    </div>
                    <div class="flex items-center mb-6 flex-wrap">
                        <fa-icon icon="filter" class="text-sm text-black mr-4" />
                        <LocationProgramFilter
                            class="flex mr-5"
                            dense
                            hide-details
                            outlined
                            clearable
                            v-model="locationProgramFilter"
                        />
                        <div class="w-160 mr-5">
                            <date-range-picker
                                v-model="dateRange"
                                :outlined="true"
                                :dense="true"
                                :solo="false"
                                :single-line="true"
                                :maxDate="null"
                                :minDate="null"
                            ></date-range-picker>
                        </div>
                        <v-btn
                            depressed
                            class="mr-4"
                            data-cy="resetFiltersHandler"
                            @click="resetFiltersHandler"
                        >Reset</v-btn>
                    </div>
                    <div :class="{'disabled pointer-events-none': isDisabled}">
                        <ReportTable
                            :table-data="reportEndpoints"
                            :date-range="dateRange"
                            :location-program-id="locationProgramId"
                            @download="download"
                        ></ReportTable>
                    </div>
                </div>
            </div>
            <div class="col-span-2 xl:col-span-1">
                <div class="rounded p-4 l-box-shadow bg-white-pure">
                    <div class="flex pb-8 items-center">
                        <ReportSvg class="text-primary-light w-10 mr-2" />
                        <h1 class="text-black mr-2 text-xl lg:text-2xl" data-cy="name">
                            Staff Reports
                        </h1>
                    </div>

                    <div class="flex items-center mb-6">
                        <div class="text-sm">
                            Select staff, assessment type & date range to download the report.
                        </div>
                    </div>
                    <Report
                        :staff-assessments-data="staffAssessmentsData"
                        :source="source"
                        :assessments-data="assessmentsData"
                        :loading="loadingAssessmentsData"
                        :assessmentList="assessmentList"
                        :staff-endpoints = "staffEndpoints"
                        @setAssessmentFilter="setAssessmentFilter"
                        @setAssessmentTypeFilter="setAssessmentTypeFilter"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {format, subDays} from 'date-fns'
import snakecaseKeys from 'snakecase-keys'
import DateRangePicker from '@/components/partials/DateRangePicker'
import ReportSvg from '@/assets/icons/nav/icons_reports.svg'
import ReportTable from '@/components/reports/partials/ReportTable'
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import LocationProgramFilter from '@/components/shared/LocationProgramFilter'
import Report from '@/components/reports/Report'
import camelcaseKeys from 'camelcase-keys'
import axiosInstance from '@/utils/axios'
import REPORTS_LIST from '@/json/reports-endpoints.json'

export default {
    name: 'Reports',
    components: {ReportSvg, DateRangePicker, ReportTable, LocationProgramFilter, Report},
    mixins: [FileMethods, MessageDialog],
    data() {
        return {
            dataAssessmentFilter: {
                startDate: null,
                endDate: null,
                locationProgramId: null,
                assessment: null
            },
            reportEndpoints: [...REPORTS_LIST],
            staffEndpoints: [
                {
                    name: 'Assessments Due',
                    to: 'assessments-due',
                    endPoint: ''
                },
            ],
            assessmentList: [],
            staffAssessmentsData: [],
            yearOptions: [],
            locationProgramFilter: {locationProgramId: ''},
            defaultDateRange: [
                format(subDays(new Date(), 6), 'yyyy-MM-dd'),
                format(new Date(), 'yyyy-MM-dd')
            ],
            dateRange: [],
            staff: [],
            assessmentFilter: {
                start_date: null,
                end_date: null,
                location_program_id: null,
                output_as: null,
                assessment_type: null
            },
            assessmentTypeFilter: {
                start_date: '2019-01-05',
                end_date: '2022-12-05',
                location_program_id: null,
                output_as: 'csv',
                assessment_type: 'COMPREHENSIVE_NONRESIDENTIAL'
            },
            source: this.$route.name,
            assessmentsData: [],
            staffData: [],
            loadingAssessmentsData: true,
            loadingStaffAssessmentsData: true,
            pagination: {
                offset: 0,
                limit: 10
            },
            filter: {
                locationProgram: null,
                status: null,
                worker: null
            }
        }
    },
    async beforeMount() {
        if (!this.$can('view', 'reports')) {
            return await this.$router.push({name: 'dashboard'})
        }
        this.dateRange = [...this.defaultDateRange]
    },
    mounted() {
        this.getAssessment()
        this.getAssessmentType()
        this.changeEndpoints()
    },
    methods: {
        filterHandlerValue(value) {
            this.dateRangeValue = value
        },
        changeAssessment(value) {
            this.dataAssessmentFilter.assessment = value
        },
        changeSelect(value) {
            this.dataFilter.staff = value
            this.dataAssessmentFilter.assessment = value
            this.$emit('setStaffFilter', this.dataFilter)
        },
        resetFiltersHandler() {
            this.locationProgramFilter = {locationProgramId: ''}
            this.dateRange = [...this.defaultDateRange]
            this.filter.locationProgram = null
            this.filter.status = null
            this.filter.worker = null
        },
        async download({ endPoint, data, type }) {
            let payload = {
                ...data,
                startDate: this.dateRange[0]
                    ? format(new Date(this.dateRange[0]), 'yyyy-MM-dd')
                    : null,
                endDate: this.dateRange[1]
                    ? format(new Date(this.dateRange[1]), 'yyyy-MM-dd')
                    : null,
                locationProgramId: this.locationProgramId ?? null,
            }
            if (type === 'CODED_REPORT') {
                payload.locationProgramId = this.locationProgramId ? [this.locationProgramId] : []
            }
            payload = snakecaseKeys(payload, {deep: true})
            axiosInstance({
                method: 'POST',
                url: endPoint,
                data: payload,
                responseType: 'blob'
            })
                .then((resp) => {
                    const fileUrl = URL.createObjectURL(resp.data)
                    const fileLink = document.createElement('a')
                    const contentDisposition = resp.headers['content-disposition']
                    const defaultFileName = 'report.pdf'
                    let filename = ''
                    if (contentDisposition) {
                        // the filename should be the second argument here, if it's not
                        try {
                            filename = contentDisposition
                                ? contentDisposition.split('=')[1]
                                : defaultFileName || ''
                        } catch (e) {
                            console.log(
                                'did not return the filename in the content-disposition header'
                            )
                            filename = defaultFileName || ''
                        }
                    } else {
                        filename = defaultFileName || ''
                    }
                    fileLink.href = fileUrl
                    fileLink.setAttribute('target', '_blank')
                    fileLink.setAttribute('download', filename)
                    document.body.appendChild(fileLink)
                    fileLink.click()
                    this.type = 'success'
                    this.message = 'Successfully downloaded document'
                })
                .catch((err) => {
                    console.log('document download error', err)
                    this.type = 'error'
                    this.message =
                        'Sorry, your file could not be downloaded at this time, please contact your system administrator'
                })
                .finally(() => {
                    if (!this.showMessage)
                        this.$emit('show-message', {message: this.message, type: this.type})
                    else this.showMessage({duration: 5000})
                })
        },
        dateRangeInput(value) {
            this.dateRange = value
        },
        setAssessmentFilter(assessmentFilter) {
            this.assessmentFilter = assessmentFilter
            this.getAssessment()
        },
        async getAssessment() {
            try {
                this.loadingAssessmentsData = true
                const data = await axiosInstance.post(
                    `/reports/user/${this.loggedInUser.userId}/my_incomplete_assessments/`,
                    snakecaseKeys(this.assessmentFilter)
                )
                this.assessmentsData = camelcaseKeys(data.data)
                this.loadingAssessmentsData = false
            } catch (error) {
                this.assessmentsData = []
                this.loadingAssessmentsData = false
            }
        },
        setAssessmentTypeFilter(assessmentTypeFilter) {
            this.assessmentTypeFilter = assessmentTypeFilter
            this.getAssessmentType()
        },
        async getAssessmentType() {
            try {
                this.loadingAssessmentsData = true
                const data = await axiosInstance.get(`/reports/static/list_assessment_types/`)
                this.assessmentList  = data.data.map(e => ({'value': e[0], 'text': e[1]}))
                this.loadingAssessmentsData = false
            } catch (error) {
                this.assessmentList = []
                this.loadingAssessmentsData = false
            }
        },
        async updateClientList(clientList) {
            this.loading = false
            this.clientList = clientList
        },
        updatePagination({page, itemsPerPage}) {
            if (itemsPerPage * (page - 1) > this.total) return

            this.pagination.limit = itemsPerPage
            this.pagination.offset = this.pagination.limit * (page - 1)
        },
        resetPage() {
            this.pagination.offset = 0
            this.$refs.episodeTable.setPage(1)
        },
        refreshAlerts() {
            this.$apollo.queries.alerts.refetch()
        },
        changeEndpoints() {
            this.staffEndpoints[0].endPoint = `/reports/user/${this.loggedInUser.userId}/my_incomplete_assessments/`
        },
    },
    computed: {
        ...mapState({
            siteSections: (state) => state.app.siteSections,
            loggedInUser: state => state.app.loggedInUser,
        }),
        locationProgramId() {
            return this.locationProgramFilter?.locationProgramId
        },
        isDisabled() {
            return !this.dateRange.length || !this.locationProgramId
        },
    }
}
</script>
